<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        class="text-capitalize mx-0 mt-4"
        @click.stop="showDialog"
      >{{buttonText}}</v-btn>
    </template>
    <v-card class="google-font">
      <v-card-title
        class="grey lighten-2 py-3 px-4"
        primary-title
      >
        One more step...
      </v-card-title>

      <v-card-text class="pa-4">
        BugSeq reports are rich with data, and can be customized to each use-case. Please provide your email so we can walk you through the reports and customizations available.
      </v-card-text>

      <v-form
        ref="form"
        v-model="valid"
        class="mx-5"
      >
        <v-layout column>
          <v-flex xs12 md6>
            <v-text-field
              v-model="firstName"
              prepend-icon="person"
              :rules="nameRules"
              label="First Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="lastName"
              prepend-icon="person"
              :rules="nameRules"
              label="Last Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="email"
              prepend-icon="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 align-self-center>
            <v-btn
              color="primary"
              :disabled="!valid"
              class="text-capitalize my-4"
              @click="submit()"
            >
              See Report
              <v-icon right dark>arrow_right_alt</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { dispatchSubmitForm } from "@bugseq-site/www/src/store/modules/api/actions";

const ComponentProps = Vue.extend({
  props: {
    buttonText: String,
    href: String,
    dontShowButton: {
      type: Boolean,
      default: false,
    }
  },
});

@Component
export default class ClickThroughDialogButton extends ComponentProps {
  private dialog: boolean = false;
  private valid: boolean = false;
  private firstName: string = "";
  private lastName: string = "";
  private nameRules: { (name: string | undefined): boolean | string; }[] = [(v) => !!v || "Name is required"];
  private email: string = "";
  private emailRules: { (email: string | undefined): boolean | string; }[] = [
    (v) => !!v || "E-mail is required",
    (v) => (v && /.+@.+\..+/.test(v)) || "E-mail must be valid",
  ];

  private localStorageKey = "reportViewData-v0"

  private async mounted() {
    if (this.dontShowButton) {
      await this.showDialog()
    }
  }

  private async showDialog() {
    const sharedDataRaw = localStorage.getItem(this.localStorageKey);
    if (sharedDataRaw) {
      const parsed = JSON.parse(sharedDataRaw)
      this.firstName = parsed.firstName
      this.lastName = parsed.lastName
      this.email = parsed.email
      await this.submit()
    } else {
      this.dialog = true
    }
  }

  private async submit() {
    const sharedData = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    }
    localStorage.setItem(this.localStorageKey, JSON.stringify(sharedData));

    await dispatchSubmitForm(this.$store, {
      form: "report_view",
      body: {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        message: `User clicked-through to ${this.href}`,
      },
    });
    window.location.href = this.href;
  }
}
</script>
