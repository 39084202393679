<template>
  <v-container class="pa-0 mt-5">
    <p class="home-section-title my-4">
      Rapid and accurate analysis for microbiology labs
    </p>
    <v-layout wrap row>
      <v-flex xs12 sm6 class="px-2 py-2">
        <v-card class="border-line card-border-teal full-height">
          <v-layout column fill-height>
            <v-card-title class="mt-2">For Lab Directors</v-card-title>
            <v-flex grow>
              <v-card-text>
                Whether your microbiology lab is looking to get started with
                sequencing or a high-volume sequencing operation, read on to see
                how BugSeq can help. When you're ready to take your lab's
                sequencing to the next level, get in touch.
              </v-card-text>
            </v-flex>
            <v-card-actions>
              <v-btn
                color="teal darken-1 white--text"
                to="#contact"
                class="text-capitalize mt-1 mb-4 ml-1"
                >Get In Touch</v-btn
              >
              <v-btn
                flat
                color="teal darken-1"
                to="/pricing"
                class="text-capitalize mt-1 mb-4 ml-1"
                >See Plans</v-btn
              >
            </v-card-actions>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 class="px-2 py-2">
        <v-card class="border-line card-border-blue full-height">
          <v-layout column fill-height>
            <v-card-title class="mt-2"
              >For Molecular Scientists & Bioinformaticians</v-card-title
            >
            <v-flex grow>
              <v-card-text>
                Start analyzing in minutes - Sign up to receive 10 trial
                samples. Upload your raw Illumina/Nanopore/PacBio data and get
                results within a few hours. Interested in learning more? Check
                out
                <a href="#about">how it works</a>.
              </v-card-text>
            </v-flex>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="redirectToSignUp()"
                class="text-capitalize mt-1 mb-4 ml-1"
                >Get Started</v-btn
              >
            </v-card-actions>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { redirectToSignUp } from "@bugseq-site/www/src/utils";

@Component({
  methods: {
    redirectToSignUp,
  }
})
export default class GetStarted extends Vue {}
</script>

<style scoped>
.full-height {
  height: 100%;
}

.border-line {
  border-left: 8px solid;
}

.card-border-blue {
  border-color: #1976d2; /* blue darken-2 */
}

.card-border-orange {
  border-color: #e65100; /* orange darken-4 */
}

.card-border-teal {
  border-color: #00897b; /* teal darken-1 */
}

.card-border-purple {
  border-color: #9c27b0; /* purple */
}
</style>
