<template>
  <v-container fluid class="my-4 google-font">
    <v-layout wrap align-center justify-center row fill-height>
      <v-flex shrink>
        <ClickThroughDialogButton
          :href="getHref()"
          buttonText="Download"
          :dontShowButton="true"
        ></ClickThroughDialogButton>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ClickThroughDialogButton from "@bugseq-site/www/src/components/ClickThroughDialogButton.vue";

const ComponentProps = Vue.extend({
  props: {
    text: String,
    showButton: {
      type: Boolean,
      default: true,
    },
  },
});

@Component({
  components: {
    ClickThroughDialogButton,
  },
  metaInfo() {
    return {
      title: "View",
    };
  },
})
export default class DemoFile extends ComponentProps {
  private mounted() {
    if (!this.showButton) {
      const href = this.getHref()
      window.location.href = href
    }
  }

  getHref(): string {
    return this.$route.params.url
  }
}
</script>
</style>
